<template>
  <div id="usagePie">
    <div v-if="hasData" >
      <!-- resourceType storage disabled until data structur and interval defined -->
      <b-form-group v-if="false" :label="$t('comp.usagebars.restype.label')">
        <b-form-select v-model="selectedResourceType" :options="resourceTypeOptions" @change="repopulatePie()" id="resourcetype-select"></b-form-select>
      </b-form-group>
      <Pie
        :chart-options="chartOptions"
        :chart-data="chartData"
        chart-id="pie-chart"
        dataset-id-key="label"
        :plugins="plugins"
        css-classes=""
        :styles="styles"
        :width="400"
        :height="height"
      />
      <p v-if="!subType" class="mt-2 text-center">
        {{ $t('comp.usagepie.description') }}
      </p>
      <p v-else-if="subType === 'cpu' && isExceeded === true" class="mt-2 text-center">
        {{ $t('comp.usagepiecpu.exceeded.description', {maximum: chartData.datasets[0].data[0], exceeded: chartData.datasets[0].data[1]}) }}
      </p>
      <p v-else-if="subType === 'cpu' && isExceeded === false" class="mt-2 text-center">
        {{ $t('comp.usagepiecpu.available.description', {used: chartData.datasets[0].data[0], available: chartData.datasets[0].data[1]}) }}
      </p>
      <p v-else-if="subType === 'gpu' && isExceeded === true" class="mt-2 text-center">
        {{ $t('comp.usagepiegpu.exceeded.description', {maximum: chartData.datasets[0].data[0], exceeded: chartData.datasets[0].data[1]}) }}
      </p>
      <p v-else class="mt-2 text-center">
        {{ $t('comp.usagepiegpu.available.description', {used: chartData.datasets[0].data[0], available: chartData.datasets[0].data[1]}) }}
      </p>
    </div>
    <div v-else class="text-center">
      {{ $t('comp.usagepie.nodata', {target: this.target, subtype: this.subType}) }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { Pie } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement } from 'chart.js'
import UsageService from '@/services/usage.service'

ChartJS.register(Title, Tooltip, Legend, ArcElement)

export default {
  name: 'UsagePie',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: { Pie },
  props: {
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
    view: {
      type: String,
      required: true
    },
    target: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    subType: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 250
    }
  },
  data () {
    return {
      hasData: false,
      isExceeded: false,
      altColors: [],
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: ['#4053d3', '#ddb310', '#b51d14', '#00beff', '#fb49b0', '#00b25d', '#cacaca'],
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      selectedResourceType: 'compute',
      resourceTypeOptions: [
        { value: 'compute', text: this.$i18n.t('comp.usagebars.restype.compute') },
        { value: 'storage', text: this.$i18n.t('comp.usagebars.restype.storage') }
      ]
    }
  },
  created () {
    this.hasData = false
    this.populatePie(this.view, this.type, this.target, this.selectedResourceType, this.subType)
  },
  methods: {
    populatePie (view, type, target, resourceType, subType) {
      const usageService = new UsageService(view)
      usageService.getDataForPieplot(target, { targetType: type, resourceType: resourceType, subType: subType }).then(
        response => {
          // console.log('Found ' + view + ' -> ' + type + ' pieplot data for ' + resourceType)
          if (!_.isEmpty(response)) {
            this.hasData = true
            this.isExceeded = response?.isExceeded
            this.chartData.labels = response.labels
            this.chartData.datasets[0].data = response.data
            if (this?.subType && !this.isExceeded) { // Color green if available if exceeded
              this.chartData.datasets[0].backgroundColor[1] = '#00b25d'
            } else if (this?.subType && this.isExceeded) { // Color red if exceeded
              this.chartData.datasets[0].backgroundColor[1] = '#b51d14'
            }
          }
        }
      ).catch(
        error => {
          console.log('Error while requesting ' + type + 'pieplot data as ' + view + ': ' + error)
        }
      )
    },
    repopulatePie () {
      this.chartData.labels = []
      this.chartData.datasets[0].data = []
      this.populatePie(this.view, this.type, this.target, this.selectedResourceType, this.subType)
    }
  }
}
</script>
