<template>
  <div id="voucherForm">
    <b-card class="mx-2 mx-md-5 mb-3" :header="cardHeader">
      <b-form-group v-if="formFields[view][action].voucherId.isVisible" :label="$t('comp.voucherform.voucherId.label')"
                    :description="$t('comp.voucherform.voucherId.description')">
        <b-form-input v-model="voucher.form.voucherId" :disabled="formFields[view][action].voucherId.isDisabled"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].forCode.isVisible && !voucher.generateBulk" :label="$t('comp.voucherform.forCode.label')"
                    :description="$t('comp.voucherform.forCode.description')">
        <b-form-input v-model="voucher.form.forCode" :disabled="formFields[view][action].forCode.isDisabled && !voucher.generateBulk"
                      :required="formFields[view][action].forCode.isRequired && !voucher.generateBulk"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].forCode.isVisible && voucher.generateBulk" :label="$t('comp.voucherform.requestedVouchers.label')"
                    :description="$t('comp.voucherform.requestedVouchers.description')">
        <b-form-input v-model="voucher.form.requestedVouchers" type="number" min="1"
                     :placeholder="$t('comp.voucherform.requestedVouchers.placeholder')"
                     :disabled="formFields[view][action].requestedVouchers.isDisabled && voucher.generateBulk"
                     :required="formFields[view][action].requestedVouchers.isRequired && voucher.generateBulk"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].forProject.isVisible" :label="$t('comp.voucherform.forProject.label')"
                    :description="$t('comp.voucherform.forProject.description')">
        <!-- Synchronize calculated validity based on project only on create-->
        <ProjectSelect v-if="action === 'add'" :selected-project.sync="voucher.form.forProject" :selected-validity.sync="voucher.form.forValidity"
                       :view="view" :is-disabled="formFields[view][action].forProject.isDisabled"/>
        <ProjectSelect v-if="action === 'edit'" :selected-project.sync="voucher.form.forProject" :loadValidity="false"
                       :view="view" :is-disabled="formFields[view][action].forProject.isDisabled"/>
        <ProjectSelect v-if="action === 'show'" :selected-project.sync="voucher.form.forProject" :loadValidity="false"
                       :view="view" :is-disabled="formFields[view][action].forProject.isDisabled"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].forValidity.isVisible"
                    :label="$t('comp.voucherform.forvalidity.label')" label-for="for-validity"
                    :description="$t('comp.voucherform.forvalidity.description')">
        <b-form-datepicker no-flip id="for-validity" :showDecadeNav="voucher.showDateTimeDecadeNav"
                           :disabled="formFields[view][action].forValidity.isDisabled"
                           v-model="voucher.form.forValidity" v-bind="datePickerLabels || {}"
                           class="mb-2" :value-as-date="true"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].state.isVisible" :label="$t('comp.voucherform.state.label')"
                    label-for="state" :description="$t('comp.voucherform.state.description')">
        <StateSelect :selected-state.sync="voucher.form.state" entity="voucher" :view="view"
                     :is-disabled="formFields[view][action].state.isDisabled"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].account.isVisible" :label="$t('comp.voucherform.account.label')"
                    :description="$t('comp.voucherform.account.description')">
        <b-form-input type="text" v-model="voucher.form.account" :disabled="formFields[view][action].account.isDisabled"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].usedBy.isVisible" :label="$t('comp.voucherform.usedBy.label')"
                    :description="$t('comp.voucherform.usedBy.description')">
        <UserSelect :selected-user.sync="voucher.form.usedBy" :view="view"
                       :is-disabled="formFields[view][action].usedBy.isDisabled"/>
      </b-form-group>
      <b-form-checkbox v-if="formFields[view][action].generateBulk.isVisible" :disabled="formFields[view][action].generateBulk.isDisabled"
                       v-model="voucher.generateBulk" name="generateBulk" switch>
        {{ $t('comp.voucherform.generatebulk.label') }}
      </b-form-checkbox>
    </b-card>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import datepickerMixin from '@/mixins/datepicker.mixin'
import StateSelect from '@/components/generic/select/StateSelect'
import ProjectSelect from '@/components/generic/select/ProjectSelect'
import UserSelect from '@/components/generic/select/UserSelect'

export default {
  name: 'VoucherForm',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, datepickerMixin],
  components: {
    StateSelect,
    ProjectSelect,
    UserSelect
  },
  props: {
    voucher: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      validator: (action) => {
        return ['add', 'edit', 'show'].includes(action)
      }
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formFields: {
        admin: {
          add: {
            voucherId: { isVisible: true, isDisabled: true },
            forCode: { isVisible: true, isDisabled: true },
            forProject: { isVisible: true, isDisabled: false },
            forValidity: { isVisible: true, isDisabled: false },
            state: { isVisible: true, isDisabled: false },
            account: { isVisible: false, isDisabled: true },
            usedBy: { isVisible: false, isDisabled: true },
            generateBulk: { isVisible: false, isDisabled: true },
            requestedVouchers: { isVisible: false, isDisabled: true, isRequired: false }
          },
          edit: {
            voucherId: { isVisible: true, isDisabled: true },
            forCode: { isVisible: true, isDisabled: true },
            forProject: { isVisible: true, isDisabled: false },
            forValidity: { isVisible: true, isDisabled: false },
            state: { isVisible: true, isDisabled: false },
            account: { isVisible: true, isDisabled: true },
            usedBy: { isVisible: true, isDisabled: true },
            generateBulk: { isVisible: false, isDisabled: true },
            requestedVouchers: { isVisible: false, isDisabled: true, isRequired: false }
          }
        },
        support: {
          show: {
            voucherId: { isVisible: true, isDisabled: true },
            forCode: { isVisible: true, isDisabled: true },
            forProject: { isVisible: true, isDisabled: true },
            forValidity: { isVisible: true, isDisabled: true },
            state: { isVisible: true, isDisabled: true },
            account: { isVisible: true, isDisabled: true },
            usedBy: { isVisible: true, isDisabled: true },
            generateBulk: { isVisible: false, isDisabled: true },
            requestedVouchers: { isVisible: false, isDisabled: true }
          }
        },
        advisor: {
          show: {
            voucherId: { isVisible: true, isDisabled: true },
            forCode: { isVisible: true, isDisabled: true },
            forProject: { isVisible: true, isDisabled: true },
            forValidity: { isVisible: true, isDisabled: true },
            state: { isVisible: true, isDisabled: true },
            account: { isVisible: true, isDisabled: true },
            usedBy: { isVisible: true, isDisabled: true },
            generateBulk: { isVisible: false, isDisabled: true },
            requestedVouchers: { isVisible: false, isDisabled: true }
          }
        },
        manager: {
          add: {
            voucherId: { isVisible: false, isDisabled: true },
            forCode: { isVisible: true, isDisabled: true },
            forProject: { isVisible: true, isDisabled: true },
            forValidity: { isVisible: true, isDisabled: false },
            state: { isVisible: true, isDisabled: true },
            account: { isVisible: false, isDisabled: true },
            usedBy: { isVisible: false, isDisabled: true },
            generateBulk: { isVisible: true, isDisabled: false },
            requestedVouchers: { isVisible: true, isDisabled: false, isRequired: true }
          }
        }
      }
    }
  },
  computed: {
    cardHeader () {
      return this.$i18n.t(`comp.voucherform.${this.action}.label`)
    }
  }
}
</script>

<style scoped>
</style>
