<template>
  <div id="invitationForm">
    <b-card class="mx-2 mx-md-5 mb-3" :header="cardHeader">
      <b-form-group v-if="formFields[view][action].invitationId.isVisible" :label="$t('comp.invitationform.invitationId.label')"
                    :description="$t('comp.invitationform.invitationId.description')">
        <b-form-input v-model="invitation.form.invitationId" :disabled="formFields[view][action].invitationId.isDisabled"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].forMail.isVisible && !invitation.sendBulk" :label="$t('comp.invitationform.forMail.label')"
                    :description="$t('comp.invitationform.forMail.description')">
        <b-form-input type="email" v-model="invitation.form.forMail" :disabled="formFields[view][action].forMail.isDisabled && !invitation.sendBulk"
                      :required="formFields[view][action].forMail.isRequired && !invitation.sendBulk"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].forBulk.isVisible && invitation.sendBulk" :label="$t('comp.invitationform.forBulk.label')"
                    :description="$t('comp.invitationform.forBulk.description')">

        <b-form-tags separator="," :placeholder="$t('comp.invitationform.forBulk.placeholder')"
                     v-model="invitation.form.forBulk"
                     :tag-validator="mailValidator"
                     :invalidTagText="$t('comp.invitationform.forBulk.invalidtag')"
                     :duplicateTagText="$t('comp.invitationform.forBulk.duplicatetag')"
                     :disabled="formFields[view][action].forBulk.isDisabled && invitation.sendBulk"
                     :required="formFields[view][action].forBulk.isRequired && invitation.sendBulk"/>

      </b-form-group>
      <b-form-group v-if="formFields[view][action].forProject.isVisible" :label="$t('comp.invitationform.forProject.label')"
                    :description="$t('comp.invitationform.forProject.description')">
        <!-- Synchronize calculated validity based on project only on create-->
        <ProjectSelect v-if="action === 'add'"  :selected-project.sync="invitation.form.forProject" :selected-validity.sync="invitation.form.forValidity"
                       :view="view" :is-disabled="formFields[view][action].forProject.isDisabled"/>
        <ProjectSelect v-if="action === 'edit'" :selected-project.sync="invitation.form.forProject" :loadValidity="false"
                       :view="view" :is-disabled="formFields[view][action].forProject.isDisabled"/>
        <ProjectSelect v-if="action === 'show'" :selected-project.sync="invitation.form.forProject" :loadValidity="false"
                       :view="view" :is-disabled="formFields[view][action].forProject.isDisabled"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].supervisor.isVisible" :label="$t('comp.invitationform.supervisor.label')"
                    label-for="supervisor" :description="$t('comp.invitationform.supervisor.description')">
        <b-form-input type="text" v-model="invitation.form.supervisor" :disabled="formFields[view][action].supervisor.isDisabled"
                      :required="formFields[view][action].supervisor.isRequired"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].message.isVisible" :label="$t('comp.invitationform.message.label')"
                    :description="$t('comp.invitationform.message.description')">
        <b-form-input type="text" v-model="invitation.form.message" :disabled="formFields[view][action].message.isDisabled"
                      :required="formFields[view][action].message.isRequired"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].forValidity.isVisible"
                    :label="$t('comp.invitationform.forvalidity.label')" label-for="for-validity"
                    :description="$t('comp.invitationform.forvalidity.description')">
        <b-form-datepicker no-flip id="for-validity" :showDecadeNav="invitation.showDateTimeDecadeNav"
                           :disabled="formFields[view][action].forValidity.isDisabled"
                           v-model="invitation.form.forValidity" v-bind="datePickerLabels || {}"
                           class="mb-2" :value-as-date="true"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].state.isVisible" :label="$t('comp.invitationform.state.label')"
                    label-for="state" :description="$t('comp.invitationform.state.description')">
        <StateSelect :selected-state.sync="invitation.form.state" entity="invitation" :view="view"
                     :is-disabled="formFields[view][action].state.isDisabled"/>
      </b-form-group>
      <b-form-group v-if="formFields[view][action].account.isVisible" :label="$t('comp.invitationform.account.label')"
                    :description="$t('comp.invitationform.account.description')">
        <b-form-input type="text" v-model="invitation.form.account" :disabled="formFields[view][action].account.isDisabled"/>
      </b-form-group>
      <b-form-checkbox v-if="formFields[view][action].createPolicy.isVisible" :disabled="formFields[view][action].createPolicy.isDisabled"
                       v-model="invitation.createPolicy" name="createPolicy" switch>
        {{ this.invitation.createPolicy ? $t('comp.invitationform.createPolicy.true.label') : $t('comp.invitationform.createPolicy.false.label') }}
      </b-form-checkbox>
      <b-form-checkbox v-if="formFields[view][action].sendBulk.isVisible" :disabled="formFields[view][action].sendBulk.isDisabled"
                       v-model="invitation.sendBulk" name="sendBulk" switch>
        {{ $t('comp.invitationform.sendbulk.label') }}
      </b-form-checkbox>
      <b-form-checkbox v-if="formFields[view][action].sendMail.isVisible" :disabled="formFields[view][action].sendMail.isDisabled"
                       v-model="invitation.sendMail" name="sendMail" switch>
        {{ $t('comp.invitationform.sendmail.label') }}
      </b-form-checkbox>
    </b-card>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import datepickerMixin from '@/mixins/datepicker.mixin'
import StateSelect from '@/components/generic/select/StateSelect'
import ProjectSelect from '@/components/generic/select/ProjectSelect'

export default {
  name: 'InvitationForm',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, datepickerMixin],
  components: {
    StateSelect,
    ProjectSelect
  },
  props: {
    invitation: {
      type: Object,
      required: true
    },
    action: {
      type: String,
      validator: (action) => {
        return ['add', 'edit', 'show'].includes(action)
      }
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formFields: {
        admin: {
          add: {
            invitationId: { isVisible: true, isDisabled: true },
            forMail: { isVisible: true, isDisabled: false, isRequired: true },
            forProject: { isVisible: true, isDisabled: false },
            supervisor: { isVisible: true, isDisabled: false, isRequired: false },
            forValidity: { isVisible: true, isDisabled: false },
            message: { isVisible: true, isDisabled: false, isRequired: false },
            state: { isVisible: true, isDisabled: false },
            account: { isVisible: false, isDisabled: true },
            createPolicy: { isVisible: false, isDisabled: true },
            sendMail: { isVisible: true, isDisabled: false },
            sendBulk: { isVisible: false, isDisabled: true },
            forBulk: { isVisible: false, isDisabled: true, isRequired: false }
          },
          edit: {
            invitationId: { isVisible: true, isDisabled: true },
            forMail: { isVisible: true, isDisabled: false, isRequired: true },
            forProject: { isVisible: true, isDisabled: false },
            supervisor: { isVisible: true, isDisabled: false, isRequired: false },
            forValidity: { isVisible: true, isDisabled: false },
            message: { isVisible: true, isDisabled: false, isRequired: false },
            state: { isVisible: true, isDisabled: false },
            account: { isVisible: true, isDisabled: true },
            createPolicy: { isVisible: false, isDisabled: true },
            sendMail: { isVisible: false, isDisabled: true },
            sendBulk: { isVisible: false, isDisabled: true },
            forBulk: { isVisible: false, isDisabled: true, isRequired: false }
          }
        },
        support: {
          show: {
            invitationId: { isVisible: true, isDisabled: true },
            forMail: { isVisible: true, isDisabled: true },
            forProject: { isVisible: true, isDisabled: true },
            supervisor: { isVisible: true, isDisabled: true },
            forValidity: { isVisible: true, isDisabled: true },
            message: { isVisible: true, isDisabled: true },
            state: { isVisible: true, isDisabled: true },
            account: { isVisible: true, isDisabled: true },
            createPolicy: { isVisible: false, isDisabled: true },
            sendMail: { isVisible: false, isDisabled: true },
            sendBulk: { isVisible: false, isDisabled: true },
            forBulk: { isVisible: true, isDisabled: true }
          }
        },
        advisor: {
          show: {
            invitationId: { isVisible: true, isDisabled: true },
            forMail: { isVisible: true, isDisabled: true },
            forProject: { isVisible: true, isDisabled: true },
            supervisor: { isVisible: true, isDisabled: true },
            forValidity: { isVisible: true, isDisabled: true },
            message: { isVisible: true, isDisabled: true },
            state: { isVisible: true, isDisabled: true },
            account: { isVisible: true, isDisabled: true },
            createPolicy: { isVisible: false, isDisabled: true },
            sendMail: { isVisible: false, isDisabled: true },
            sendBulk: { isVisible: false, isDisabled: true },
            forBulk: { isVisible: true, isDisabled: true }
          }
        },
        manager: {
          add: {
            invitationId: { isVisible: false, isDisabled: true },
            forMail: { isVisible: true, isDisabled: false, isRequired: true },
            forProject: { isVisible: true, isDisabled: true },
            supervisor: { isVisible: this.invitation.showSupervisor, isDisabled: !this.invitation.showSupervisor, isRequired: this.invitation.showSupervisor },
            forValidity: { isVisible: true, isDisabled: false },
            message: { isVisible: true, isDisabled: false, isRequired: false },
            state: { isVisible: true, isDisabled: true },
            account: { isVisible: false, isDisabled: true },
            createPolicy: { isVisible: true, isDisabled: false },
            sendMail: { isVisible: false, isDisabled: true },
            sendBulk: { isVisible: true, isDisabled: false },
            forBulk: { isVisible: true, isDisabled: false, isRequired: true }
          }
        }
      }
    }
  },
  computed: {
    cardHeader () {
      return this.$i18n.t(`comp.invitationform.${this.action}.label`)
    }
  },
  methods: {
    mailValidator (mail) {
      // Individual mail validator function (Regex from https://v2.vuejs.org/v2/cookbook/form-validation.html)
      return mail === mail.toLowerCase() && mail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    }
  }
}
</script>

<style scoped>
</style>
