<template>
  <div id="projectUsageInfo">
    <div v-if="hasData" >
      <div v-if="view === 'manager'">
        <hr/>
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="`projectusageinfo-${project}`" variant="light" class="text-left">
                <b-icon-bar-chart-line-fill/> {{ $t('comp.projectusageinfo.usage.title') }}
                <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
                <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
              </b-button>
            </b-card-header>
            <b-collapse :id="`projectusageinfo-${project}`" accordion="projectusageinfos" role="tabpanel">
              <b-card-body>
                <b-card-text class="mx-0 mx-md-3">
                  <b-form-group :description="$t('comp.projectusageinfo.selectyear.description')">
                    <b-form-select v-model="selectedYear" :options="yearOptions"></b-form-select>
                  </b-form-group>
                  <hr/>
                  <div v-for="usage in usages" :key="usage.usageId">
                    <ul v-if="usage.forYear == selectedYear">
                      <li><strong>{{ $t('comp.projectusageinfo.resource.label') }}:</strong> {{ usage.resource }}</li>
                      <li v-if="selectedYear == thisYear"><strong>{{ $t('comp.projectusageinfo.thismonth.label') }}:</strong> {{ usage.thisMonth }} {{ usage.unit }}</li>
                      <li><strong>{{ $t('comp.projectusageinfo.nummonths.label') }}:</strong> {{ usage.numMonths }}</li>
                      <li v-if="usage.yearTotal"><strong>{{ $t('comp.projectusageinfo.yeartotal.label') }}:</strong> {{ usage.yearTotal }} {{ usage.unit }}</li>
                      <li><strong>{{ $t('comp.projectusageinfo.allyears.label') }}:</strong> {{ usage.allYears }} {{ usage.unit }}</li>
                      <li v-if="usage.resourceQuota !== ''"><strong>{{ $t('comp.projectusageinfo.resourcequota.label') }}:</strong> {{ usage.resourceQuota }}</li>
                      <li v-if="usage.quotaPercent !== 0"><strong>{{ $t('comp.projectusageinfo.quotapercent.label') }}:</strong> {{ usage.quotaPercent }}%</li>
                    </ul>
                    <hr v-if="usage.forYear == selectedYear"/>
                  </div>
                  <b-button variant="primary" class="w-100" v-b-modal="`usagebar-modal-${project}`">
                    <b-icon-box-arrow-up-right/> {{ $t('comp.projectusageinfo.showusage.label') }}
                  </b-button>
                  <b-button variant="primary" class="mt-2 w-100" v-b-modal="`usagepie-modal-${project}`">
                    <b-icon-box-arrow-up-right/> {{ $t('comp.projectusageinfo.showusagepie.label') }}
                  </b-button>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        <b-modal :id="`usagebar-modal-${project}`" :title="$t('comp.projectusageinfo.usage.display.label', { project: project })" size="xl">
          <div class="overflow-auto px-md-5">
            <UsageBars :view="this.view" :type="this.type" :target="project" :height="400"/>
          </div>
        </b-modal>
        <b-modal :id="`usagepie-modal-${project}`" :title="$t('comp.projectusageinfo.usage.display.label', { project: project })" size="xl">
          <b-row>
            <b-col cols="12">
              <UsagePie :view="this.view" :type="this.type" :target="project" :height="325"/>
            </b-col>
          </b-row>
          <hr class="mt-1 mb-3">
          <b-row>
            <b-col cols="12" lg="6">
              <UsagePie :view="this.view" :type="this.type" sub-type="cpu" :target="project"/>
            </b-col>
            <b-col cols="12" lg="6">
              <UsagePie :view="this.view" :type="this.type" sub-type="gpu" :target="project"/>
            </b-col>
          </b-row>
        </b-modal>
      </div>
      <div v-else-if="view === 'admin' || view === 'support' || view === 'advisor'">
        <b-form-group :description="$t('comp.projectusageinfo.selectyear.description')">
          <b-form-select v-model="selectedYear" :options="yearOptions"></b-form-select>
        </b-form-group>
        <b-row>
          <b-col v-for="usage in usages" :key="usage.usageId" :class="(usage.forYear == selectedYear) ? 'mb-3' : 'd-none'" cols="12" lg="6">
            <b-card v-if="usage.forYear == selectedYear">
              <template #header>
                <div class="d-flex align-items-center">
                  <b-icon-hdd-rack class="mr-2"/> {{ usage.resource }}
                  <div v-if="selectedYear == thisYear" class="ml-auto">
                    <b-icon-asterisk v-if="usage.lastMonthIndicator === 0" variant="info" :title="$t('comp.projectusageinfo.indicator.undefined.label')" class="cursor-help"/>
                    <b-icon-heart v-if="usage.lastMonthIndicator === 1" variant="danger" :title="$t('comp.projectusageinfo.indicator.danger.label')" class="cursor-help"/>
                    <b-icon-heart-half v-if="usage.lastMonthIndicator === 2" variant="warning" :title="$t('comp.projectusageinfo.indicator.warning.label')" class="cursor-help"/>
                    <b-icon-heart-fill v-if="usage.lastMonthIndicator === 3" variant="success" :title="$t('comp.projectusageinfo.indicator.success.label')" class="cursor-help"/>
                  </div>
                </div>
              </template>
              <b-card-text>
                <li v-if="selectedYear == thisYear"><strong>{{ $t('comp.projectusageinfo.thismonth.label') }}:</strong> {{ usage.thisMonth }} {{ usage.unit }}</li>
                <li v-if="selectedYear == thisYear"><strong>{{ $t('comp.projectusageinfo.lastmonth.label') }}:</strong> {{ usage.lastMonth }} {{ usage.unit }}</li>
                <li><strong>{{ $t('comp.projectusageinfo.nummonths.label') }}:</strong> {{ usage.numMonths }}</li>
                <li v-if="usage.yearTotal"><strong>{{ $t('comp.projectusageinfo.yeartotal.label') }}:</strong> {{ usage.yearTotal }} {{ usage.unit }}</li>
                <li><strong>{{ $t('comp.projectusageinfo.allyears.label') }}:</strong> {{ usage.allYears }} {{ usage.unit }}</li>
                <li v-if="usage.resourceQuota !== ''"><strong>{{ $t('comp.projectusageinfo.resourcequota.label') }}:</strong> {{ usage.resourceQuota }}</li>
                <li v-if="usage.quotaPercent !== 0"><strong>{{ $t('comp.projectusageinfo.quotapercent.label') }}:</strong> {{ usage.quotaPercent }}%</li>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
        <hr class="mt-1"/>
        <UsageBars :view="this.view" :type="this.type" :target="project" :queryYear="selectedYear"/>
        <hr class="my-3"/>
        <b-row>
          <b-col cols="12" lg="4">
            <UsagePie :view="this.view" :type="this.type" :target="project"/>
          </b-col>
          <b-col cols="12" lg="4">
            <UsagePie :view="this.view" :type="this.type" sub-type="cpu" :target="project"/>
          </b-col>
          <b-col cols="12" lg="4">
            <UsagePie :view="this.view" :type="this.type" sub-type="gpu" :target="project"/>
          </b-col>
        </b-row>
      </div>
    </div>
    <div v-else>
      <hr v-if="view === 'manager'"/>
      <div class="ml-4">
        {{ $t('comp.projectusageinfo.nodata', {type: this.type, target: project}) }}
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import UsageService from '@/services/usage.service'
import UsageBars from '@/components/generic/chart/UsageBars'
import UsagePie from '@/components/generic/chart/UsagePie'
import { i18nMixin } from '@/mixins/i18n.mixin'
import yearSelectMixin from '@/mixins/yearSelect.mixin'

export default {
  name: 'ProjectUsageInfo',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, yearSelectMixin],
  components: {
    UsageBars,
    UsagePie
  },
  props: {
    project: {
      type: String,
      required: true
    },
    view: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      type: 'project',
      hasData: false,
      usages: []
    }
  },
  created () {
    this.hasData = false
    this.getUsages(this.project)
  },
  computed: {
  },
  methods: {
    getUsages (projectId) {
      if (this.view === 'manager') {
        return this.$store.dispatch({ type: 'manager/getProjectUsages', targetId: projectId }).then(
          response => {
            if (!_.isEmpty(response)) {
              this.hasData = true
              this.usages = response
            }
          }
        ).catch(
          error => {
            console.log('Error while requesting project usage as manager: ' + error)
          }
        )
      } else if (this.view === 'admin' || this.view === 'support' || this.view === 'advisor') {
        const usageService = new UsageService(this.view)
        usageService.getCurrentForProject(projectId).then(
          response => {
            if (!_.isEmpty(response)) {
              this.hasData = true
              this.usages = response
            }
          }
        ).catch(
          error => {
            console.log('Error while requesting project usage as admin or support or advisor: ' + error)
          }
        )
      }
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
