import { i18nMixin } from '@/mixins/i18n.mixin'
export default {
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  computed: {
    datePickerLabels () {
      if (this.messagesAvailable) {
        return {
          labelPrevDecade: this.$i18n.t('datePicker.previousDecade.label'),
          labelPrevYear: this.$i18n.t('datePicker.previousYear.label'),
          labelPrevMonth: this.$i18n.t('datePicker.previousMonth.label'),
          labelCurrentMonth: this.$i18n.t('datePicker.currentMonth.label'),
          labelNextMonth: this.$i18n.t('datePicker.nextMonth.label'),
          labelNextYear: this.$i18n.t('datePicker.nextYear.label'),
          labelNextDecade: this.$i18n.t('datePicker.nextDecade.label'),
          labelToday: this.$i18n.t('datePicker.today.label'),
          labelSelected: this.$i18n.t('datePicker.selected.label'),
          labelNoDateSelected: this.$i18n.t('datePicker.noDateSelected.label'),
          labelCalendar: this.$i18n.t('datePicker.calendar.label'),
          labelNav: this.$i18n.t('datePicker.nav.label'),
          labelHelp: this.$i18n.t('datePicker.help.label')
        }
      }
      return {}
    }
  }
}
