<template>
  <div id="commentCard">
    <b-card class="mx-2 mx-md-5 my-3">
      <template #header>
        {{ $t('comp.commentcard.label') }}
      </template>

      <b-form-group v-if="view === 'admin' || view === 'manager'" :label="$t('comp.commentcard.message.label')" :description="$t('comp.commentcard.message.description')">
        <b-form-input :value="comment" @input="onInput" />
      </b-form-group>

      <TableHeader v-if="showTable" @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" table-id="comments-table"/>
      <b-table responsive v-if="showTable" id="comments-table" ref="comments-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" :items="commentItemProvider" sort-by="createdAt" :sort-desc="true" small striped hover>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import TableHeader from '@/components/generic/helper/TableHeader'
import CommentService from '@/services/comment.service'

export default {
  name: 'CommentCard',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    TableHeader
  },
  props: {
    targetId: {
      type: String,
      required: true
    },
    view: {
      type: String,
      required: true
    },
    newComment: {
      type: String,
      default: ''
    },
    showTable: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      totalRows: 0,
      filter: '',
      commentService: new CommentService(this.view)
    }
  },
  computed: {
    comment () {
      return this.newComment
    },
    fields () {
      const fields = [
        { key: 'createdAt', date: true },
        { key: 'byUser', select: true },
        { key: 'visibility', adminOnly: true, capitalize: true },
        { key: 'comment' },
        { key: 'changes', changeLog: true }
      ]
      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.adminOnly == null) {
          field.adminOnly = false
        }
        if (field.adminOnly) {
          field.thClass = `${this.view === 'manager' ? 'd-none' : ''}`
          field.tdClass = `${this.view === 'manager' ? 'd-none' : ''}`
        }
        if (field.capitalize) {
          field.formatter = (value, key, item) => {
            return value ? value.charAt(0).toUpperCase() + value.slice(1) : value
          }
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.commentcard.${field.key}.label`)
        }
        if (field.date) {
          field.formatter = (value, key, item) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
          }
          field.sortByFormatted = true
        }
        if (field.select) {
          field.sortKey = field.key + '.label'
          field.formatter = (value, key, item) => {
            return _.has(value, 'label') ? value.label : ''
          }
        }
        if (field.changeLog) {
          field.formatter = (value) => {
            if (value.length > 0) {
              let changes = ''
              value.forEach((elem, index) => {
                changes += ('[' + elem.field + ':')
                if (Array.isArray(elem.before)) {
                  const labelsBef = []
                  elem.before.forEach((bef) => {
                    if (bef !== null) {
                      if (typeof bef === 'object') {
                        labelsBef.push(bef.label)
                      } else {
                        labelsBef.push(bef)
                      }
                    } else {
                      labelsBef.push(bef)
                    }
                  })
                  changes += ('[' + labelsBef.join(',') + ']->')
                } else if (typeof elem.before === 'object') {
                  if (elem.before !== null) {
                    if (elem.field === 'subscriptions') {
                      changes += (JSON.stringify(elem.before) + '->')
                    } else {
                      changes += (elem.before.label + '->')
                    }
                  } else {
                    changes += (elem.before + '->')
                  }
                } else {
                  changes += (elem.before + '->')
                }
                if (Array.isArray(elem.after)) {
                  const labelsAft = []
                  elem.after.forEach((aft) => {
                    if (aft !== null) {
                      if (typeof aft === 'object') {
                        labelsAft.push(aft.label)
                      } else {
                        labelsAft.push(aft)
                      }
                    } else {
                      labelsAft.push(aft)
                    }
                  })
                  changes += ('[' + labelsAft.join(',') + ']')
                } else if (typeof elem.after === 'object') {
                  if (elem.after !== null) {
                    if (elem.field === 'subscriptions') {
                      changes += JSON.stringify(elem.after)
                    } else {
                      changes += elem.after.label
                    }
                  } else {
                    changes += elem.after
                  }
                } else {
                  changes += elem.after
                }
                if (index !== value.length - 1) {
                  changes += '], '
                } else {
                  changes += ']'
                }
              })
              return changes
            } else {
              return 'No Changes'
            }
          }
        }
      })
      return fields
    }
  },
  created () {
    this.commentService.count({ targetId: this.targetId, filter: this.filter }).then((response) => (this.rows = response))
    this.commentService.count({ targetId: this.targetId }).then((response) => (this.totalRows = response))
  },
  methods: {
    refresh () {
      this.commentService.count({ targetId: this.targetId }).then((response) => (this.totalRows = response))
      if (this.$refs['comments-table']) {
        this.$refs['comments-table'].refresh()
      }
    },
    commentItemProvider (ctx) {
      ctx.targetId = this.targetId
      return this.commentService.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    onInput (value) {
      this.$emit('update:newComment', value)
    }
  }
}
</script>

<style scoped>
</style>
