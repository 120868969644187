<template>
  <div class="row justify-content-between">
    <div class="col-12 col-md-4 justify-content-md-start">
      <b-input-group class="mb-3 d-flex justify-content-center" size="sm">
        <b-input-group-prepend v-if="searchables.length === 0">
          <b-input-group-text >
            <b-icon-filter :title="$t('filter.label')"/>
          </b-input-group-text>
        </b-input-group-prepend>
        <b-input-group-prepend v-else>
          <b-button :id="`searchables-popover${tableId}`">
            <b-icon-filter/>
          </b-button>
          <b-popover :target="`searchables-popover${tableId}`" triggers="hover" placement="bottomright">
            <template #title>{{ $t('searchables.label') }}</template>
            <ul>
              <li v-for="field in searchables" :key="field">{{ field }}</li>
            </ul>
          </b-popover>
        </b-input-group-prepend>
        <b-form-input
          style="min-width: 150px;"
          :value="filter"
          @update="onFilter"
          :placeholder="$t('filter.placeholder')"
          debounce="500"
        />
        <b-input-group-append>
          <b-button
            v-show="filter"
            @click="$emit('update:filter', '')"
            variant="outline"
          >
            <b-icon-backspace variant="primary" :title="$t('reset.label')"/> {{ $t('count.filtered.label', rows, { count: rows }) }}
          </b-button>
          <b-button
            @click="$emit('refresh')"
            variant="outline"
          >
            <b-icon-arrow-clockwise :title="$t('refresh.label')" variant="primary"/> {{ $t('count.total.label', { count: totalRows }) }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="col-6 col-md-4 d-flex justify-content-start justify-content-md-center">
      <b-pagination
        v-show="perPage!=null&&perPage<rows"
        size="sm"
        :value="currentPage"
        @input="updateCurrentPage"
        :total-rows="rows"
        :per-page="perPage"
        :aria-controls="tableId"
        first-number
        last-number
      >
      </b-pagination>
    </div>
    <div class="col-6 col-md-4 d-flex justify-content-end align-items-start mb-3">
      <b-button-group size="sm">
        <b-button v-if="rows>10" :variant="perPage==10?'primary':'outline-primary'" :disabled="perPage==10" @click="updatePerPage(10)">10</b-button>
        <b-button v-if="rows>20" :variant="perPage==20?'primary':'outline-primary'" :disabled="perPage==20" @click="updatePerPage(20)">20</b-button>
        <b-button v-if="rows>50" :variant="perPage==50?'primary':'outline-primary'" :disabled="perPage==50" @click="updatePerPage(50)">50</b-button>
        <b-button v-if="rows>100" :variant="perPage==100?'primary':'outline-primary'" :disabled="perPage==100" @click="updatePerPage(100)">100</b-button>
        <b-button :variant="perPage==null||perPage>=rows?'primary':'outline-primary'" :disabled="perPage==null||perPage>=rows" @click="updatePerPage(null)">{{ $t('all.label') }}</b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableHeader',
  i18n: {
  },
  props: {
    perPage: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    },
    rows: {
      type: Number,
      required: true
    },
    totalRows: {
      type: Number
    },
    filter: {
      type: String,
      default: ''
    },
    tableId: {
      type: String,
      default: ''
    },
    usePrefs: {
      type: Boolean,
      default: true
    },
    searchables: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  methods: {
    updatePerPage (value) {
      this.$emitCase('update:per-page', value)
    },
    updateCurrentPage (value) {
      this.$emitCase('update:current-page', value)
    },
    onFilter (value) {
      this.updateCurrentPage(1)
      this.$emit('update:filter', value)
    }
  }
}
</script>

<style scoped>
</style>
